<template>
  <el-card>
    <div slot="header">
      <span>Documents</span>
      <div style="float: right">
        <template v-if="!isEdit">
          <el-button
            class="u-bottom-margin--2x"
            icon="el-icon-edit"
            size="mini"
            type="primary"
            @click="isEdit = true"
            >Edit</el-button
          >
        </template>
        <template v-else>
          <button
            class="el-button u-bottom-margin--2x el-button--primary el-button--mini"
            :class="!isChanged && deletedList.length === 0 ? 'is-disabled' : ''"
            @click="handleUpdateDocuments"
            :disabled="!isChanged && deletedList.length === 0"
            type="button"
          >
            Save
          </button>
          <el-button
            class="u-bottom-margin--2x"
            size="mini"
            @click="isEdit = false"
            >Cancel</el-button
          >
        </template>
      </div>
    </div>
    <div class="upload-container">
      <el-upload
        action=""
        drag
        :auto-upload="false"
        multiple
        :disabled="!isEdit"
        :file-list="fileList"
        :on-change="handleChangeDocuments"
        style="width: max-content"
        accept="image/jp,.pdf"
        :show-file-list="false"
        list-type="picture"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text" :class="!isEdit && 'text-black-50'">
          Click or drag file to this area to upload <br />
          Support for a single or bulk upload
        </div>
      </el-upload>
      <el-row type="flex" class="file-list-container">
        <el-col :span="24" v-for="(file, index) in fileList" :key="index">
          <div class="file-item">
            <a :href="file.location" target="_blank" rel="noreferrer noopener">
              {{ file.fileName || file.name }}
            </a>

            <div>
              <a :href="file.location" v-if="!isEdit" :download="file.location">
                <i class="el-icon-download"></i>
              </a>
              <el-popconfirm
                :title="`Are you sure you want to delete this file?`"
                @confirm="handleDeleteDocument(file, index)"
                icon="el-icon-info"
                icon-color="red"
                v-if="isEdit"
              >
                <el-button
                  slot="reference"
                  icon="el-icon-delete"
                  type="danger"
                  plain
                  round
                  size="mini"
                ></el-button>
              </el-popconfirm>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>

<script>
import { providersService } from "../../../services";
import extendedFunctions from "../../../utils/extendedFunctions";

export default {
  name: "ProviderDocuments",
  data() {
    return {
      isEdit: false,
      fileList: [],
      deletedList: [],
      isChanged: false,
    };
  },
  mounted() {
    document.querySelector(".el-upload-dragger").style = "cursor: not-allowed";
    this.getProviderDocuments();
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    async getProviderDocuments() {
      try {
        const {
          data: { data },
        } = await providersService.getProviderDocuments(this.id);
        this.fileList = data;
      } catch (error) {
        this.$notify({
          title: this.$t("GlobalsErrorTitle"),
          message: extendedFunctions.errorMessageExtractor(error),
          type: "error",
        });
      }
    },
    handleChangeDocuments(file) {
      const fileList = [...this.fileList];
      const fileIndex = fileList.findIndex((item) => {
        return item.name === file.name;
      });
      if (fileIndex === -1) {
        fileList.push(file);
      } else {
        fileList.splice(fileIndex, 1, file);
      }
      this.isChanged = true;
      this.fileList = fileList;
    },
    async handleUpdateDocuments() {
      const form = new FormData();
      this.fileList.map((file) => {
        form.append("files", file.raw);
      });
      if (this.deletedList.length > 0) {
        const deletedList = this.deletedList.map((item) => {
          return item.fileKey;
        });
        try {
          await providersService.deleteProviderDocuments(this.id, deletedList);
        } catch (error) {
          this.$notify({
            title: this.$t("GlobalsErrorTitle"),
            message: extendedFunctions.errorMessageExtractor(error),
            type: "error",
          });
        }
      }
      if (this.isChanged) {
        try {
          await providersService.updateProviderDocuments(this.id, form);
        } catch (error) {
          this.$notify({
            title: this.$t("GlobalsErrorTitle"),
            message: extendedFunctions.errorMessageExtractor(error),
            type: "error",
          });
        }
      }
      this.isEdit = false;
      this.getProviderDocuments(this.id);
    },
    async handleDeleteDocument(file, index) {
      this.fileList.splice(index, 1);
      this.deletedList.push(file);
    },
  },
  watch: {
    isEdit(value) {
      const upload = document.querySelector(".el-upload-dragger");
      if (!value) {
        upload.style = "cursor: not-allowed";
      } else {
        upload.style = "cursor: pointer";
      }
    },
  },
};
</script>

<style lang="scss">
.text-black-50 {
  color: rgba($color: #000000, $alpha: 0.5) !important;
}
.upload-container {
  width: min-content;
}
.file-list-container {
  flex-wrap: wrap;
  .file-item {
    display: flex;
    font-size: 12px;
    margin-top: 10px;
    justify-content: space-between;
    a {
      text-decoration: none;
      max-width: 200px;
      text-wrap: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .upload-icon {
      font-size: 18px;
    }
  }
}
</style>
